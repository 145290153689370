import React from 'react'
import { HeadingTertiary } from '../../components/atoms/Heading/HeadingTertiary'
import { CardService } from '../../components/molecules/Card/CardService'
import { LayoutService } from '../../components/organisms/Layout/LayoutService'
import { LinkAnchor } from '../../components/atoms/Link/LinkAnchor'
import { SEO } from '../../components/data/SEO'
import { css } from '@emotion/react'
import { ENGLISH_TITLE } from '../../components/data/English'

const cardList1 = [
  {
    serviceName: 'コールセンター',
    imagePathPc: '/service/img/hr/hr1.jpg',
    imagePathSp: '/service/img/hr/hr1sp.jpg',
    title: 'お客様の窓口となり営業活動を徹底サポート',
    content:
      '創業時から磨き上げてきた、当社グループの中核事業です。長年の業務を通じて蓄積した人材管理とマッチングのノウハウやナレッジを継承。高いスキルを持った登録スタッフを擁し、業務内容に合わせて活躍できる人材を提供します。',
    groupList: [
      { text: '株式会社キャスティングロード', url: 'https://www.cr2.co.jp/service/hr/call-center/' },
      { text: '株式会社ジョブス', url: 'https://jobs-cp.jp/service/' },
    ],
    columnNumber: 2,
  },
  {
    serviceName: '事務',
    imagePathPc: '/service/img/hr/hr2.jpg',
    imagePathSp: '/service/img/hr/hr2sp.jpg',
    title: '幅広い業務に対応できる人材を常時多数確保',
    content:
      'データ集計・分析のスペシャリスト、一般事務・営業事務などのオフィスワークを経験した人材を多数確保。事務能力だけでなく、適性や希望も考慮し、クライアントのニーズにベストマッチした優秀な人材を提供します。',
    groupList: [
      { text: '株式会社キャスティングロード', url: 'https://www.cr2.co.jp/service/hr/office/' },
    ],
    columnNumber: 2,
  },
  {
    serviceName: '販売・接客',
    imagePathPc: '/service/img/hr/hr3.jpg',
    imagePathSp: '/service/img/hr/hr3sp.jpg',
    title: '接客・販売活動を包括的にバックアップ',
    content:
      '訪日外国人も増加し続け、接客現場での人材確保ニーズもますます加速する中、最適な人材をご提案します。全国主要都市圏で多彩なキャストを擁し、「人材派遣」はもちろんのこと、店舗運営すべてを請け負う「販売代行」も対応が可能です。',
    groupList: [
      { text: '株式会社キャスティングロード', url: 'https://www.cr2.co.jp/service/hr/sales/' },
      { text: '株式会社ジョブス', url: 'https://jobs-cp.jp/service/' },
    ],
    columnNumber: 2,
  },
  {
    serviceName: 'リゾート',
    imagePathPc: '/service/img/hr/hr4.jpg',
    imagePathSp: '/service/img/hr/hr4sp.jpg',
    title: 'リゾート地・地方都市の地方創生を目指す',
    content:
      '北海道から沖縄まで「派遣登録者の行ってみたい場所」が職場になります。働く目的や行きたい場所でお仕事を選ぶ新しいスタイルの派遣です。短期だけではなく、正社員登用や長期での勤務をサポートし、リゾート地や地方を活性化できるように全国各地より人材のご提供をいたします。',
    groupList: [
      { text: '株式会社キャスティングロード', url: 'https://www.cr2.co.jp/service/hr/resort-work/' },
    ],
    columnNumber: 2,
  },
  {
    serviceName: '物流',
    imagePathPc: '/service/img/hr/hr5.jpg',
    imagePathSp: '/service/img/hr/hr5sp.jpg',
    title: '物流業界に特化したデリバリースタッフ派遣',
    content:
      '「マンパワー」が非常に重要な、物流業界でのデリバリースタッフ派遣を行っています。軽貨物から重い荷物まで、配送内容に適したスタッフを幅広く確保。急にスタッフ確保が必要な際も、フレキシブルに対応します。',
    groupList: [{ text: '株式会社ジョブス', url: 'https://jobs-cp.jp/service/' }],
    columnNumber: 2,
  },
  {
    serviceName: '倉庫',
    imagePathPc: '/service/img/hr/hr6.jpg',
    imagePathSp: '/service/img/hr/hr6sp.jpg',
    title: '若手からベテランまで最適な人材を提供',
    content:
      '各種業務の経験者に加え、体力に自信のある若手人材も提供。物流・倉庫内における体力を要する業務にも問題なく対応します。フォークリフトオペレーターや製造系各種技能工といった、採用難易度の高い有資格者の派遣も可能です。',
    groupList: [{ text: '株式会社ジョブス', url: 'https://jobs-cp.jp/service/' }],
    columnNumber: 2,
  },
  {
    serviceName: 'イベント',
    imagePathPc: '/service/img/hr/hr7.jpg',
    imagePathSp: '/service/img/hr/hr7sp.jpg',
    title: '集客、運営管理までイベントをサポート',
    content:
      '多岐にわたるイベントにおいて、集客から当日の運営管理までをサポート。接客スキルの高い人材を多く確保していますので、販促活動から商品のPR・キャンペーン業務など、あらゆる分野に柔軟に対応します。',
    groupList: [{ text: '株式会社ジョブス', url: 'https://jobs-cp.jp/service/' }],
    columnNumber: 2,
  },
  {
    serviceName: '介護・看護',
    imagePathPc: '/service/img/hr/hr8.jpg',
    imagePathSp: '/service/img/hr/hr8sp.jpg',
    title: '採用難易度の高い有資格者も紹介可能',
    content:
      'リハビリ職・介護職・看護職といった採用難易度の高い経験者、有資格者を多数確保し紹介が可能です。また未経験ながら、意欲ある若手も提供しており、サポート体制も万全です。',
    groupList: [
      { text: '株式会社ジョブス', url: 'https://jobs-cp.jp/service/' },
    ],
    columnNumber: 2,
  },
  {
    serviceName: 'ITエンジニア',
    imagePathPc: '/service/img/hr/hr9.jpg',
    imagePathSp: '/service/img/hr/hr9sp.jpg',
    title: '作業工程に的確にマッチする技術者を提供',
    content:
      '市場のニーズにあった技術研修を実施、様々な得意分野を持つ技術者が幅広く在籍しています。要件定義などの上流工程から実制作が得意な技術者まで、プロジェクトの作業工程に合致する人材を提供します。',
    groupList: [{ text: '株式会社CRドットアイ', url: 'https://crdoti.co.jp/service/' }],
    columnNumber: 2,
  },
]

const cardList2 = [
  {
    serviceName: '紹介予定派遣',
    imagePathPc: '/service/img/hr/hr10.jpg',
    imagePathSp: '/service/img/hr/hr10sp.jpg',
    title: 'ニーズに合わせ、ミスマッチを減らす人材採用',
    content:
      '毎月増加する登録者から最適な人材をご紹介。直接雇用を目的とした、紹介予定派遣においてもノウハウを蓄積。質の高い雇用環境を創造していきます。',
    groupList: [
      { text: '株式会社キャスティングロード', url: 'https://www.cr2.co.jp/service/permanent/' },
    ],
    columnNumber: 2,
  },
  {
    serviceName: 'ハイキャリア',
    imagePathPc: '/service/img/hr/hr11.jpg',
    imagePathSp: '/service/img/hr/hr11sp.jpg',
    title: 'IPO準備企業や上場企業とのマッチングに強み',
    content:
      '大手エグゼクティブサーチ出身のコンサルタントが求人企業の事業フェーズや重要な経営課題を的確に把握。そのうえで課題解決・戦略実現に必要となる即戦力人材をご紹介。 お客様と求職者それぞれに寄り添い、真の介在価値をご提供します。',
    groupList: [
      { text: '株式会社キャスティングロード', url: 'https://www.cr2.co.jp/highcareer/' },
    ],
    columnNumber: 2,
  },
]

const cardList3 = [
  {
    serviceName: '就労移行支援',
    imagePathPc: '/service/img/hr/hr12.jpg',
    imagePathSp: '/service/img/hr/hr12sp.jpg',
    title: '採用から就労後の定着まで、障がい者雇用の課題解決',
    content:
      '一般企業への就職を目指す障がいをお持ちの方に対し、職業訓練や就職活動支援などの様々な支援を行うことで一般企業への就職の支援をします。',
    groupList: [{ text: '株式会社パレット', url: 'https://crpalette.co.jp/to-company/retention-support/' }],
    columnNumber: 2,
  },
  {
    serviceName: 'サテライトオフィス',
    imagePathPc: '/service/img/hr/hr13.jpg',
    imagePathSp: '/service/img/hr/hr13sp.jpg',
    title: '障がい者の方々が安心して働ける環境づくりの提供',
    content:
      '郊外エリアにサテライトオフィスを設置し、障がいを抱える方々が安心して働けるよう、快適な環境整備づくりと、専門スタッフによる細やかなサポートを心がけています。',
    groupList: [{ text: '株式会社パレット', url: 'https://crpalette.co.jp/satellite-office/' }],
    columnNumber: 2,
  },
]

const Page = () => {
  return (
    <LayoutService
      heading="ヒューマンリソース事業"
      headingEnglish={ENGLISH_TITLE.HumanResource}
      catchCopy="CRGグループの中核ビジネス。ITを活用し、必要なときに必要な人材を提供いたします。"
    >
      <div>
        <ul className="grid grid-cols-2 bg-white pc:px-8 pc:py-6 gap-x-2 gap-y-4 pc:gap-0 pc:flex pc:flex-wrap pc:space-x-4">
          {[
            { text: '人材派遣サービス', to: 'heading1' },
            { text: '人材紹介サービス', to: 'heading2' },
            {
              text: '障がい者雇用<br class="pc:hidden"/>サービス',
              to: 'heading3',
            },
          ].map((item, i) => (
            <LinkAnchor key={i} {...item} />
          ))}
        </ul>
        <section className="bg-white pc:py-12 pc:px-8 mt-14 pc:mt-8">
          <div id="heading1">
            <HeadingTertiary label="人材派遣サービス" />
          </div>
          <ul
            css={css`
              & > li:nth-of-type(2) > div > div.bg-bg10 {
                @media screen and (min-width: 1200px) {
                  height: 104px; //横のボックスと高さ合わせる
                }
              }
            `}
            className="grid mt-10 gap-x-8 gap-y-12 place-items-center pc:place-items-start pc:grid-cols-2"
          >
            {cardList1.map((card, i) => (
              <CardService key={i} {...card} />
            ))}
          </ul>
          <div className="p-6 mt-12 bg-bg10">
            <b className="inline-block text-xl font-medium font-Rajdhani text-primary">
              POINT
            </b>
            <p className="mt-2 font-medium leading-normal text-md">
                CRGグループの人材派遣サービスにより
              <span className="font-bold">
                若手、女性、シニア、グローバル人材
              </span>
              といった方々が様々な職場で活躍しています。
            </p>
          </div>
        </section>
        <section className="pt-20 bg-white pc:py-12 pc:px-8 pc:mt-8">
          <div id="heading2">
            <HeadingTertiary label="人材紹介サービス" />
          </div>
          <ul className="grid mt-10 gap-x-8 gap-y-12 place-items-center pc:place-items-start pc:grid-cols-2">
            {cardList2.map((card, i) => (
              <CardService key={i} {...card} />
            ))}
          </ul>
        </section>
        <section className="pt-20 bg-white pc:py-12 pc:px-8 pc:mt-8">
          <div id="heading3">
            <HeadingTertiary label="障がい者雇用サービス" />
          </div>
          <ul className="grid mt-10 gap-x-8 gap-y-12 place-items-center pc:place-items-start pc:grid-cols-2">
            {cardList3.map((card, i) => (
              <CardService key={i} {...card} />
            ))}
          </ul>
        </section>
      </div>
    </LayoutService>
  )
}

export default Page

/**
 * headタグの内容
 */
/** */
export const Head = () => (
  <SEO title="ヒューマンリソース事業" url="/service/hr" />
)
